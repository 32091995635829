import {Deserializable} from './deserialize';


export interface ICredentials {
  consumerKey?: string;
  consumerSecret?: string;
  accessToken?: string;
  jwt?: string;
}

export class Credentials implements ICredentials, Deserializable {
  consumerKey?: string;
  consumerSecret?: string;
  accessToken?: string;
  jwt?: string;

  deserialize(input: any) {
    Object.assign(this, input);
    return this;
  }
}
